import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import book from '@iconify/icons-ic/book';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import cameraFill from '@iconify/icons-eva/camera-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'Modules',
    path: PATH_PAGE.modules,
    icon: <Icon icon={cameraFill} {...ICON_SIZE} />
  },
  {
    title: 'How it works',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.howitworks
  },
  {
    title: 'About',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.about
  },/* 
  {
    title: 'Pricing',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing
  }, */
  {
    title: 'Contact',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.contact
  }
];

export default menuConfig;
