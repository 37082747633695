import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Icon } from '@iconify/react';
import { Container, Divider, IconButton, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoFooter from '../../components/LogoFooter';
import SoftTypography from '../../components/SoftTypography';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate } = useLocales();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" >

        <Stack key="kontakt" spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignText: 'center' }}>
          <SoftTypography color="primary" textGradient variant="h4" fontWeight="bold">
            {translate('footerContactHeading')}
          </SoftTypography>

          <IconButton
            key={"linkedin"}
            color="primary"
            sx={{ p: 0, pl: 0 }}
            href={"https://www.linkedin.com/company/workplacerai"}
            target="_blank"
            rel="noreferrer"
            aria-label={"linkedin"}
          >
            <Icon icon={linkedinFill} width={16} height={16} />
          </IconButton>
          <Stack key="kontaktunder" spacing={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignText: 'center' }}>
            <Link
              href="mailto:info@workplacer.ai"
              key="info@workplacer.ai"
              target="_blank"
              rel="noreferrer"
              color="inherit"
              variant="body2"
              sx={{ display: 'block' }}
            >
              info@workplacer.ai
            </Link>
            <Link
              href="https://maps.app.goo.gl/oRz54puyj2qqMZq68"
              target="_blank"
              rel="noreferrer"
              key="gmaps"
              color="inherit"
              variant="body2"
              sx={{ display: 'block' }}
            >
              Gåsgränd 2, Stockholm
            </Link>
          </Stack>
        </Stack>
        <LogoFooter sx={{ mt: 2, mx: { xs: 'auto', md: 'inherit' } }} />

        <Typography
          component="p"
          variant="body2"
          sx={{
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'center' }
          }}
        >
          © 2024. Workplacer.ai
        </Typography>
      </Container>
    </RootStyle>
  );
}
