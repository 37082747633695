import { Suspense, lazy } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
// IMPORT COMPONENTS
// Dashboard

const Home = Loadable(lazy(() => import("../pages/dashboard/intro/Home")));
const Howitworks = Loadable(lazy(() => import("../pages/dashboard/intro/Howitworks")));
const About = Loadable(lazy(() => import("../pages/dashboard/intro/About")));
const Pricing = Loadable(lazy(() => import("../pages/dashboard/intro/Pricing")));
const Modules = Loadable(lazy(() => import("../pages/dashboard/intro/Modules")));
const Contact = Loadable(lazy(() => import("../pages/dashboard/intro/Contact")));

// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

export default function Router() {
  return useRoutes([

    // Dashboard Routes
    {
      path: "/",
      element: (
        <MainLayout />
      ),
      children: [
        { element: <Home /> },
        { path: "", element: <Home /> },
        { element: <Navigate to="/" replace /> },
        { path: "how-it-works", element: <Howitworks /> },
        { path: "modules", element: <Modules /> },
        { path: "pricing", element: <Pricing /> },
        { path: "about", element: <About /> },
        { path: "contact", element: <Contact /> },
  

      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "comingsoon", element: <ComingSoon /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
