import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

import useSettings from '../hooks/useSettings';
import brand from "../assets/images/lohow.png";


// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

const APP_BAR_MOBILE = 64;
export default function Logo({ sx }) {
  const { themeMode } = useSettings();

  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: APP_BAR_MOBILE - 4, ...sx, py: 2 }}>
      <img src={brand} alt="Logo" />
   
    </Box>
  );
}
