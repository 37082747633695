import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';
import brandwidth from "../assets/images/logowidth.png";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const { themeMode } = useSettings();
  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 200, height: '100%', ...sx }}>
      {themeMode === 'light' ? <img src={brandwidth} alt="Logo" /> : <img src={brandwidth} alt="Logo" />}
    </Box>
  );
}
